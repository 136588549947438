<template>
  <!-- <v-dialog v-model="dialog" transition="dialog-bottom-transition" min-width="520" class="mx-10">
    <template v-slot:activator="{ on, attrs }">
      <v-btn text outlined color="secondary" class="subsecondary mr-2" v-bind="attrs" v-on="on">
        subscribe
      </v-btn>
    </template> -->
  <v-card elevation="0" v-if="this.$store.getters.isLoggedIn">
    <!-- {{ pricingItem }} -->
    <!-- <v-alert v-show="valert.active" dismissible :color="valert.color" border="left" elevation="2" colored-border
      :icon="valert.icons">
      {{ valert.text }}
    </v-alert> -->
    <!-- <template> -->
    <div class="pricing-price">
      <v-img :src="require('@/assets/images/pages/card-basic-influencer.png')" alt="Pricing offer" class="pricing-img"
        height="10em">
        <div class="pricing-price-img" v-if="pricingItem">
          <strong>{{ pricingItem.name }} offer . ${{ pricingItem.price }}</strong><br />
        </div>
      </v-img>
    </div>
    <v-stepper v-model="e6" vertical elevation="0">
      <v-stepper-step :complete="e6 > 1" step="1">
        Méthode de paiement
        <small>Selectionner la method de paiement</small>
      </v-stepper-step>

      <v-stepper-content step="1">
        <v-progress-circular v-if="!paymentmethods.length" class="ma-auto" :size="70" :width="7" color="primary"
          indeterminate></v-progress-circular>
        <v-list dense>
          <v-list-item v-for="item in paymentmethods" :key="item.id" link
            @click="payment.payment_method = item; e6 = 2">
            <v-list-item-avatar>
              <v-img :src="item.image"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-subtitle>
                {{ item.name }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-icon>{{ icons.mdiArrowRight }}</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-stepper-content>

      <v-stepper-step :complete="e6 > 2" step="2">
        Effectuer le Paiement
        {{ payment.payment_method.operator }}
      </v-stepper-step>
      <v-stepper-content step="2">
        <v-card elevation="0" class="mb-12">
          <v-row dense class="pt-4">
            <v-col cols="4">
              <v-select v-model="payment.currency_iso" dense outlined label="Devise" :items="['XAF', 'USD', 'EUR']"
                @change="convertCurrency"></v-select>
            </v-col>
            <v-col cols="8">
              <v-text-field v-model="payment.amount" dense label="Montant souscription" outlined
                :prefix="payment.currency_iso" @blur="convertCurrency" :readonly="true"></v-text-field>
            </v-col>
          </v-row>
          <v-btn color="info" :loading="loader.convertion" block @click="convertCurrency">
            = {{ payment.credit }} Credit
          </v-btn>
          <v-divider class="ma-3"></v-divider>
          <v-text-field v-if="['2', '3'].includes(payment.payment_method.id)" v-model="payment.phonenumber" dense
            outlined label="Votre numero de telephone"></v-text-field>
          <div v-else-if="['6', '7'].includes(payment.payment_method.id)">
            <v-row dense>
              <v-col cols="12">
                <v-text-field v-model="payment.card_owner" dense class="mb-3" prefix="" outlined
                  label="Nom complet du proprietaire"></v-text-field>
                <v-text-field v-model="payment.card_number" dense prefix="" outlined label="Numero de la carte">
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <label>Date d'expiration</label>
                <v-row dense>
                  <v-col>
                    <v-select v-model="payment.card_month" dense outlined label="Mois"
                      :items="['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12',]"></v-select>
                  </v-col>
                  <v-col>
                    <v-select v-model="payment.card_year" dense outlined label="Annee" :items="years"></v-select>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="payment.card_cvv" dense label="CVV" outlined prefix="CVV"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="payment.address" dense label="Adresse" outlined></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field v-model="payment.city" dense label="Ville" outlined></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field v-model="payment.cp" dense label="Code postal" outlined></v-text-field>
              </v-col>
            </v-row>
          </div>
          <p v-if="['8', '4'].includes(payment.payment_method.id)">
            Vous serez redirige vers une fenetre de paiement.
          </p>

          <v-card-actions class="pa-0">
            <v-btn @click="e6 = 1">
              Retour
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn :loading="loader.purchase" class="mt-2" color="primary" @click="purchase">
              <v-icon style="color: white" size="20">
                {{ icons.mdiAccountCircle }}
              </v-icon>
              payer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content>

      <v-stepper-step :complete="e6 > 3" step="3">
        Verification du paiement
      </v-stepper-step>

      <v-stepper-content step="3">
        <v-card elevation="0" class="mb-12">
          <v-card-text>
            <div class="alert alert-info" v-html="payment.message"></div>
            <div v-if="payment.paymentlink" class="text-center">
              <br>
              si la fenetre de paiement ne s'ouvre pas automatiquement, veuillez svp cliquer sur
              ce bouton<br><br>
              <v-btn small @click="opendialog(payment.paymentlink)">
                Ouvrire l'interface
              </v-btn>
              <br><br>
            </div>
          </v-card-text>

          <v-card-text v-if="valert.error">
            <v-alert color="warning" v-html="valert.message"></v-alert>
          </v-card-text>
          <v-card-actions>
            <v-btn text @click="e6 = 2">
              Retour
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn :loading="loader.verify" small class="mt-4" color="primary" @click="verify">
              <v-icon style="color: white" size="20">
                {{ icons.mdiAccountCircle }}
              </v-icon>
              Vérifier mon paiement
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content>
    </v-stepper>
    <!-- <v-card-actions>
        <v-btn class="primary px-5" text @click="purchaseSubscribtion" v-if="this.$store.getters.isLoggedIn">
          Purchase
          <v-icon>{{ icons.mdiThumbUp }}</v-icon>
        </v-btn>
      </v-card-actions> -->
  </v-card>
  <!-- </v-dialog> -->
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountCircle,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiAlertRemove,
  mdiThumbUp, mdiArrowRight, mdiCreditCardOutline,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'

export default {

  props: {
    // @override
    user: {
      type: Object,
      default: () => { }
    },
    pricingItem: {
      type: Object,
      default: () => { }
    }
  },
  // data() {
  //   return {
  //     user: this.$store.getters.getUser,
  //     dialog: false,
  //     e6: 1
  //   }
  // },
  methods: {
    purchaseSubscribtion() {
      this.$store.commit("setSubscribtion", {
        isSubscribed: true,
        // level: this.pricingItem.level,
        title: pricingItem.name
      })
      this.$router.push({ name: 'home' })
    }
  },

  setup(props, { emit }) {
    const paymentmethods = ref([])
    const years = ref([])
    for (let i = 0; i < 10; i++) {
      years.value.push((new Date()).getFullYear() + i)
    }

    const payment = ref({
      currency_iso: props.user.currency_iso,
      payment_method: {},
      // credit: parseInt(props.pricingItem.price),
      credit: 0, 
      address: props.user.address,
      city: props.user.city,
      cp: '',
      amount: props.pricingItem.price
      // cp: props.user.cp,
    })
    const loader = ref({
      confirm: false,
      purchase: false,
      verify: false,
      convertion: false,
    })
    const e6 = ref(1)
    const tabs = ref(1)
    const valert = ref({
      color: '',
      text: '',
      icons: mdiEyeOutline,
      active: false,
      error: false,
      message: false,
    })

    const init = () => {
      Drequest.getPaymentMethods(props.user)
        .get(response => {
          console.log(response)
          paymentmethods.value = response.paymentmethods
        })
    }
    init()

    const verify = () => {
      valert.value.error = false
      loader.value.verify = true
      Drequest.getVerifyPurchase(payment.value)
        .get(response => {
          console.log(response)
          if (response.success) {
            Drequest.orderRefillWallet(payment.value.paymentref, payment.value.amount)
              .post(response => {
                console.log(response)
                e6.value = (1)
                tabs.value = (1)

                loader.value.verify = false
                emit('refilled')

              })
              .fail(e => {
                loader.value.verify = false
                alert('Erreur de connexion. Verifier votre connexion svp.')
              })
          } else {
            loader.value.verify = false
            valert.value.error = true
            valert.value.message = `Oops!! Transaction refusé. SVP assurez vous d'avoir validé la transaction.
            Ensuite reverifiez le paiement.<br>En cas d'un soucis veuillez contacter l'administrateur
            en mentionnant le numero de votre transaction: <b>${payment.value.paymentref}</b> `

            alert(valert.value.message)
          }
        })
        .fail(e => {
          loader.value.verify = false
          alert('Erreur de connexion. Verifier votre connexion svp.')
        })
    }

    const opendialog = (url) => {
      const params = 'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=0,height=0,left=300,top=300'
      open(url, 'Paypal', params)
    }

    const callbackPurchase = response => {
      loader.value.purchase = false
      if (!response.success) {
        window.alert(response.message)

        return false
      }

      let msg = 'Payment initiated. you will receive a message!'
      if (payment.value.payment_method.operator === 'Orange Money' || payment.value.payment_method.provider === 'OrangeMoney') {
        msg = 'Transaction initiatee. Vous recevrez un message de Orange Money. Si le message n\'apprait pas, composez le #150*50# s\'il vous plait.'
      } else if (payment.value.payment_method.operator === 'MTN Mobile Money') {
        msg = 'Transaction initiatee. Vous recevrez un message de Orange Money. Si le message n\'apprait pas, composez le *126# s\'il vous plait.'
      } else if (payment.value.payment_method.operator === 'VISA') {
        msg = 'Transaction initiatee. vous serez debite sur votre carte VISA '
      } else if (payment.value.payment_method.operator === 'MASTERCARD') {
        msg = 'Transaction initiatee. vous serez debite sur votre carte MASTERCARD '
      }

      if (payment.value.paymentlink) {
        opendialog(payment.value.paymentlink)
      }

      console.log(response)
      e6.value = 3
      payment.value = {
        ...payment.value,
        ...response.payment,
        message: msg,
      }

      // Send purchase infos
      emit('purchase', payment)

    }

    const failPurchase = e => {
      console.log(e)
      loader.value.purchase = false
      window.alert('Oops. Erreur de connexion. Verifiez votre connexion SVP!')
    }

    const purchase = () => {
      if (!payment.value.amount) {
        window.alert("Attention vous n'avez pas rempli le montant.")

        return
      }

      const date = new Date()
      const ref = `3AG${payment.value.payment_method.id}WEBAPP${props.user.id}${date.getFullYear()}`
      payment.value.reference = ref
      loader.value.purchase = true
      if (['2', '3'].includes(payment.value.payment_method.id)) {
        Drequest.purchaseWithMonetBill(props.user, payment.value)
          .raw(callbackPurchase).fail(failPurchase)
      } else if (['6', '7'].includes(payment.value.payment_method.id)) {
        if (!payment.value.card_owner || !payment.value.card_number || !payment.value.card_year || !payment.value.card_month
          || !payment.value.card_cvv || !payment.value.address || !payment.value.city || !payment.value.cp) {
          window.alert('Attention!!. Tous les champs sont obligatoires!')

          return 0
        }
        Drequest.purchaseWithPaypalCreditCard(props.user, payment.value)
          .raw(callbackPurchase).fail(failPurchase)
      } else if (['4'].includes(payment.value.payment_method.id)) {
        Drequest.purchaseWithPaypal(props.user, payment.value)
          .raw(callbackPurchase).fail(failPurchase)
      } else if (['8'].includes(payment.value.payment_method.id)) {
        Drequest.purchaseWithOMWp(props.user, payment.value)
          .raw(callbackPurchase).fail(failPurchase)
      }
    }

    const convertCurrency = () => {
      if (!payment.value.amount) {
        payment.value.credit = 0

        return
      }
      loader.value.convertion = true
      Drequest.getCreditConverted(payment.value.amount, payment.value.currency_iso)
        .post(response => {
          console.log(response)
          loader.value.convertion = false
          payment.value.credit = parseInt(response.amount)
        })
    }

    return {
      verify,
      purchase,
      opendialog,
      convertCurrency,

      e6,
      tabs,
      valert,
      payment,
      years,
      loader,
      paymentmethods,
      icons: {
        mdiEyeOutline,
        mdiArrowRight,
        mdiCreditCardOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
//@import '@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
